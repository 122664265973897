// import "./src/global.css"

exports.onRouteUpdate = ({ location, prevLocation }) => {
    if (prevLocation && typeof window !== 'undefined') {
        window?.analytics?.page(null, null, { 
            path: location.pathname,
            url: location.href
        });
    }
}
